import React from "react"

const HtbDifferenceCats = ({ htbDifferenceCat }) => {

  return (
    <section className="content very-light-blue-background bg-image">
          <div className="eighty-spacer"></div>
          <div className="content-block">
             <div className="centered-content-container">
                <div className="centered-title-holder">
                   <h3>
                      So what really makes us so different?
                   </h3>
                </div>
                <div className="sixty-spacer"></div>
             </div>
             {htbDifferenceCat[0] ? (
             <div className="one-flex-container htb-difference-slider">
    {htbDifferenceCat[0].htbDifferences.nodes.length
      ? htbDifferenceCat[0].htbDifferences.nodes.map(prod => (

        <div className="third-flex-container home-color staggerFadeIn stag-visible" key={prod.id}>
           <div className="htb-difference-title-holder">
              {prod.title}
              </div>
              <div className="htb-difference-text-holder" dangerouslySetInnerHTML={{ __html: prod.content }}/>
        </div>

      ))
    : null}
    </div>
      ) : null}
   </div>
   <img className="hands-up" src="/htbcontent/themes/htb-personal/dist/assets/img/hands-up.png" alt=""/>
   </section>
  )
}

export default HtbDifferenceCats
